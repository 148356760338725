import { IconChevronRight } from "@/icons/IconChevronRight";
import Style from "./Slider.module.scss"
import clsx from "clsx";


function SliderNextBtn({ instanceRef, currentSlide, slideBy = null, className, loaded }){
    return(
        <>
            {loaded && (
                <button 
                    className={clsx(Style.button, Style.next, className)}
                    type="button"
                    onClick={(e) =>
                        e.stopPropagation() || instanceRef.current?.moveToIdx(currentSlide + (slideBy ?? instanceRef.current.options.slides.perView))
                    }
                >
                    <IconChevronRight width="25" height="25" />
                </button>
            )}
            
        </>

    )
}

export default SliderNextBtn;