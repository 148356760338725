import { IconChevronLeft } from "@/icons/IconChevronLeft";
import Style from "./Slider.module.scss"
import clsx from "clsx";


function SliderPrevBtn({ instanceRef, currentSlide, slideBy = null, className }){
    return(
        <button 
            className={clsx(Style.button, Style.prev, className)}
            type="button"
            disabled={currentSlide === 0}
            onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.moveToIdx(currentSlide - (slideBy ?? instanceRef.current.options.slides.perView))
            }
        >
            <IconChevronLeft width="25" height="25" />
        </button>
    )
}


export default SliderPrevBtn;