"use client"

import GridColumn from "@/components/layout/GridColumn";
import Style from "./RecomendationRow.module.scss";
import { useKeenSlider } from "keen-slider/react"
import ProductRecommendation from "../ProductCard/Recommendation";
import Divider from "@/components/display/Divider";
import { useState } from "react";
import Slider, { SliderDots, SliderNextBtn, SliderPrevBtn } from "@/components/navigation/Slider";


function RecomendationRow({ title="You May Also Like", data = [] }){
    const [ currentSlide, setCurrentSlide ] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const [ sliderRef, instanceRef ] = useKeenSlider({
        initial: 0,
        slides: {
            perView: 5,
            spacing: 10,
        },
        breakpoints: {
            '(max-width: 568px)':{
                slides: {
                    perView: 2,
                    spacing: 10,
                },
            }
        },
        slideChanged(s) {
            setCurrentSlide(s.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
    })


    return(
        <>
            {Array.isArray(data) && data.length > 0 ? (
                <>
                    <GridColumn element="header" start={2} end={14}>
                        <Divider className={Style.divider} />

                        <h2 className={Style.heading}>
                            {title}
                        </h2>
                    </GridColumn>
                
                    <GridColumn element="section" start={2} end={14} className={Style.block}>
                        <SliderPrevBtn 
                            {...{ instanceRef, currentSlide }} 
                            slideBy={1}
                        />

                        <Slider ref={sliderRef} className={Style.slider}>
                            {data.map((product) => <ProductRecommendation key={product.id} {...product} />)}
                        </Slider>

                        {data.length > 10 && (
                            <SliderDots 
                                {...{ instanceRef, currentSlide, loaded }} 
                                slideBy={5}
                            />
                        )}
                        

                        <SliderNextBtn 
                            {...{ instanceRef, currentSlide, loaded }} 
                            slideBy={1}
                        />
                    </GridColumn>
                </>
            ): null}
            
        </>
        
    )
}

export default RecomendationRow;