import Card from "@/components/display/Card";
import Image from "next/image";
import Link from "next/link";
import Style from "./ProductCard.module.scss"
import dynamic from 'next/dynamic'
import createProductURL from "@/lib/utils/createProductURL";

const Price = dynamic(() => import("../Price"), { ssr: false })


function ProductRecommendation({ id, name, price, image, url}){
    return(
        <Card className={"keen-slider__slide"} noMargin>
            <Link href={createProductURL(url, id)} className={Style.link}>
                <Image 
                    src={image} 
                    width={200} 
                    height={200} 
                    alt={name} 
                    className={Style.image}
                />

                <Price value={price} size="sm" />

                <p className={Style.itemTitle}>
                    {name}
                </p>
            </Link>
        </Card>
    )
}

export default ProductRecommendation;