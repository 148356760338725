import clsx from "clsx";
import Style from "./Slider.module.scss"


function SliderDots({ loaded, instanceRef, currentSlide, slideBy = null, className }){
    return(
        <>
            {loaded && instanceRef.current && (
                <div className={clsx(Style.dots, className)}>
                    {[...Array(instanceRef.current.track.details.slides.length).keys(), ].map((idx) => {
                        if(idx % (slideBy ?? instanceRef.current.options.slides.perView)) return null
                        
                        return (
                            <button
                                key={idx}
                                onClick={() => instanceRef.current?.moveToIdx(idx)}
                                className={clsx(Style.dot, currentSlide === idx && Style.dotActive)}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}

export default SliderDots;