

/**
 * Normalizes the creation of the product page URLs
 * @param {string} url - URI or slug of the product
 * @param {string} id - ID of the Product
 * @returns string
 */

export default function createProductURL(url = "", id = null){
    let newURL = url;

    // if the last character of the url string is a '/', remove it
    newURL = (url?.[url.length - 1] === "/") && newURL.slice(0, url.length - 1)
    
    // if the first character of the URL string is a '/', remove it too
    newURL = (url?.[0] === "/" && typeof newURL === "string") ? newURL.slice(1) : newURL

    return `/p/${newURL}/-/${id}`
}