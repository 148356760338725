import SliderDots from "./SliderDots";
import SliderPrevBtn from "./SliderPrevBtn";
import SliderNextBtn from "./SliderNextBtn";
import React from "react";
import clsx from "clsx";


const Slider = React.forwardRef(({ className, children }, ref ) => {
    return(
        <div className={clsx("keen-slider", className)} ref={ref}>
            {children}
        </div>
    )
})

Slider.displayName = 'Slider';

export{
    SliderDots,
    SliderPrevBtn,
    SliderNextBtn
}


export default Slider;